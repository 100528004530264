.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3b00dc;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
