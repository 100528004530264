[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
}

.Modal {
  background-color: var(--modal-background);
  height: 400px;
  width: 600px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 60px;
  z-index: 100;
}

.Header {
  background-color: var(--modal-background);
  height: 60px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}

.Cancel {
  background-color: var(--buttons);
  color: var(--button-text);
  width: 80px;
  height: 40px;
  border-radius: 7px;
  margin-top: 5px;
  margin-left: 5px;
}
.Edit__Comment {
  color: var(--main-text);
  width: 120px;
  height: 40px;
  justify-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 5px;
}
.Edit {
  background-color: var(--disabled-buttons);
  color: var(--button-text);
  width: 80px;
  height: 40px;
  border-radius: 7px;
  margin-top: 5px;
  margin-right: 5px;
}

.Edit:enabled {
  background-color: var(--buttons);
}

.Edit:enabled:active {
  color: #7764ea;
}

.Comment {
  color: white;
  height: 340px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Input {
  color: black;
  width: 400px;
  height: 50px;
  text-align: center;
  background-color: #aaaaaa;
  outline: none;
  border-radius: 5px;
}
