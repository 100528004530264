[data-theme='light'] {
  --background: #fff;
  --main: #3c00dc;
  --text-secondary: #7f7f7f;
  --search: #aaaaaa;
  --input-text: #fff;
  --secondary-input-text: #fff;
  --ternary-input-text: #7764ea;
  --outline: #3c00dc;
  --form-bg: #000;
  --search-text: #000;
}

[data-theme='dark'] {
  --background: #000;
  --main: #3c00dc;
  --text-secondary: #7f7f7f;
  --search: #1c1c1e;
  --input-text: #6f62c1;
  --secondary-input-text: #888888;
  --ternary-input-text: #7764ea;
  --outline: ##3c00dc;
  --form-bg: #aaaaaa;
  --search-text: #fff;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 95.5vh;
  /* width: 100vw; */
  background-color: var(--background);
  overflow-y: hidden;
}

.Accessibility {
  color: var(--search-text);
}

.Links_Container {
  max-height: 100vh;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 945px;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.Links {
  margin: auto;
  background-color: #1c1c1e;
  min-width: 276px;
  padding-left: 10px;
  color: #ffffff;
  margin: 10px 0px;
  text-align: left;
  color: var(--button-text);
  padding: 5px;
  max-width: 500px;
  max-height: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 7px;
  cursor: pointer;
}

.Link_Stack {
  display: flex;
  min-width: 276px;
  flex-direction: column;
}

.Subtext {
  color: var(--text-secondary);
  font-size: 12px;
}

#Objectionable {
  margin-top: 20px;
  background-color: var(--search);
}

.Arrow {
  height: 40px;
  width: 40px;
  display: flex;
  margin-left: auto;
  color: var(--button-text);
  background-image: url('/public/Rekishi_Icons/arrow.up@2x.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  transform: rotate(90deg);
  color: var(--main);
}

.Cache {
  height: 50px;
  width: 80px;
  display: flex;
  margin-left: auto;
  color: var(--main);
}

.CheckboxContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
  margin-left: 170px;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.ToggleLabel {
  cursor: pointer;
  text-indent: -9999px;
  width: 40px;
  height: 20px;
  background: grey;
  display: block;
  border-radius: 50px;
  position: relative;
}

.ToggleLabel:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 10px;
  transition: 0.3s;
}

input:checked + .ToggleLabel {
  background: #3b00dc;
}

input:checked + .ToggleLabel:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

.ToggleLabel:active:after {
  width: 50px;
}
