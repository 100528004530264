[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #606064;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--post-overlay);
  opacity: 0.7;
  z-index: 0;
  display: flex;
}

.Modal__Container {
  height: 630px;
  width: 1000px;
  display: flex;
  margin: auto;
  border-radius: 10px;
}

.Modal {
  height: 635px;
  width: 1000px;
  top: 60px;
  position: fixed;
  border-radius: 7px;
}

.Title {
  height: 30px;
  width: 100%;
  overflow: hidden;
  background-color: var(--modal-background);
  color: var(--main-text);
  border: solid 1px var(--modal-borders);
  font-size: 15px;
  align-items: center;
  justify-content: space-between;
  padding-left: 5px;
  border-bottom: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.Close {
  height: 30px;
  width: 30px;
  align-self: center;
  justify-self: center;
  margin: auto;
  padding-top: 12px;
  padding-left: 5px;
  float: right;
  padding-right: 0px;
  z-index: 12;
  cursor: pointer;
}

.Bar::before {
  content: '';
  position: absolute;
  transform: rotate(45deg) translate(0px, -0px);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: var(--buttons);
  cursor: pointer;
}

.Bar::after {
  content: '';
  position: absolute;
  transform: rotate(-45deg) translate(0px, 0px);
  width: 22px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: var(--buttons);
  cursor: pointer;
}

.Menu {
  height: 603px;
  background-color: var(--modal-background);
  width: 100%;
  display: flex;
  border-right: solid 1px var(--modal-borders);
  border-left: solid 1px var(--modal-borders);
  border-bottom: solid 1px var(--modal-borders);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.Engage {
  max-height: 632px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  border-bottom-right-radius: 7px;
}

.Image {
  max-height: 632px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 7px;
}

.Content__Container {
  display: flex;
}

.Play {
  max-height: 632px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.5;
}

.Play::before {
  content: '';
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 35px solid #fff;
}

.Image__Vid {
  max-height: 632px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 7px;
}

.Video {
  max-height: 632px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  border-bottom-left-radius: 7px;
}

.Text {
  color: var(--main-text);
  display: flex;
  align-items: flex-start;
  height: fill;
  width: fill;
  padding: 10px;
  border-top: solid 1px var(--modal-borders);
  border-right: solid 1px var(--modal-borders);
}

.Nav {
  position: absolute;
  background-color: var(--nav-background);
  border-radius: 60%;
  margin: auto;
  background-repeat: no-repeat;
  background-position: 40% 50%;
  background-size: 40%;
  opacity: 0.75;
  top: 299px;
  height: 30px;
  width: 30px;
  cursor: pointer;
}

#Back {
  left: 0px;
}

.Nav__Outer {
  position: fixed;
  background-color: var(--nav-background);
  border-radius: 60%;
  background-repeat: no-repeat;
  background-position: 40% 50%;
  background-size: 40%;
  opacity: 0.75;
  top: 369px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

#Back {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  margin-left: 5px;
}

#Forward {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  /* background-image: url('/public/Rekishi_Icons/chevron.left@2x.png'); */
  right: 0px;
  display: inline-block;
  transform: rotate(180deg);
  margin-right: 5px;
}

#Inner__Forward {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  right: 500px;
  display: inline-block;
  transform: rotate(180deg);
  margin-right: 5px;
}

.Comment__Section {
  background-color: var(--modal-background);
  width: 100%;
  color: var(--main-text);
  height: 442px;
  border-top: solid 1px var(--modal-borders);
  overflow-y: auto;
}

.Like__Display {
  background-color: var(--modal-background);
  height: 100px;
  width: 100%;
  flex: 0 1 100px;
  padding: 10px;
  padding-left: 15px;
  border-top: solid 1px var(--modal-borders);
}

.Comment {
  background-color: var(--modal-background);
  max-height: 60px;
  max-width: 502px;
  height: 100%;
  width: 100%;
  font-size: 15px;
  display: flex;
  align-items: center;
  border-top: solid 1px var(--modal-borders);
  border-bottom-right-radius: 7px;
  padding: 10px 10px 10px 15px;
}

.Comment__Input {
  background-color: var(--modal-background);
  height: 30px;
  width: stretch;
  caret-color: var(--main-text);
  color: var(--main-text);
}

.Comment__Input::placeholder {
  color: var(--main-text);
}

.Comment__Input:focus {
  outline: none;
}

.Like__Icon {
  background-image: url('/public/Rekishi_Icons/heart@2x.png');
  display: inline-block;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.Like__Icon.liked {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x\ 2.png');
}

.Like__Icon.light {
  background-image: url('/public/Rekishi_Icons/heart@2x\ 2.png');
}

.Like__Icon.liked.light {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}

.Comment__Icon {
  background-image: url('/public/Rekishi_Icons/message@2x.png');
  display: inline-block;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-left: 10px;
}

.Comment__Icon.light {
  background-image: url('/public/Rekishi_Icons/message@2x2.png');
}

#Make__Comment__Icon {
  margin: 0px 5px 0px 0px;
}

.Post__Comment {
  background-color: var(--disabled-buttons);
  color: var(--button-text);
  border-radius: 7px;
  padding: 3.75px 7px 3.75px 7px;
}

.Post__Comment:enabled {
  background-color: var(--buttons);
}

.Post__Comment:enabled:active {
  color: var(--button-active);
}

.Post__Date {
  color: var(--main-text);
  font-size: 12px;
}

.Anime__Sub {
  color: var(--main-text);
  font-size: 12px;
}

@media (max-height: 1440px) {
  .Modal__Container {
    height: 630px;
    width: 1000px;
    display: flex;
    margin: auto;
    position: absolute;
    top: calc(100vh / 5);
    left: 0;
    right: 0;
    border-radius: 10px;
  }
}

@media (max-width: 1150px) {
  .Modal__Container {
    width: calc(100vw - 100px);
  }

  .Image {
    min-width: 400px;
  }
  .Title {
    width: calc(100vw - 100px);
  }
}

@media (max-width: 800px) {
  .Modal__Container {
    width: 400px;
  }
  .Title {
    width: 401px;
  }
  .Menu {
  }
  .Image {
    width: 100%;
    border: solid 1px var(--modal-borders);
  }
  .Engage {
    display: none;
    width: 0px;
  }

  #Inner__Forward {
    right: 0px;
  }
}
