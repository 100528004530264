[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  opacity: 0.7;
  z-index: 0;
}

.Modal {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  background-color: var(--background);
  padding-top: 15px;
  padding-right: 10px;
  top: 0px;
  right: 0px;
  position: absolute;
  padding-top: 15px;
  padding-right: 10px;
}

.Modal.open {
  height: 100%;
  width: 425px;
  display: flex;
  flex-flow: column wrap;
  background-color: var(--background);
  border: solid var(--text-secondary) 2px;
  transform: translateX(0%) translateY(0px) translateZ(0px);
  transition: all 0.45s;
  z-index: 1;
  position: fixed;
}

.Preview.open {
  display: flex;
  flex-direction: column;
  background: var(--background);
  height: 500px;
  width: 100%;
  padding: 10px;
  transform: translateX(0);
  transition: all 0.45s ease-in-out;
  overflow-x: hidden;
  justify-content: center;
  z-index: 100;
}

.Items {
  display: flex;
  height: 200px;
  width: fit-content;
}

.Close {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 10px;
}

.Close.open {
  display: flex;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  transition: all 0.45s ease-in-out;
  left: 370px;
  top: 5px;
}

.Header {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.Header.open {
  max-height: 70px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: center;
  color: var(--text-secondary);
  flex-direction: column;
  font-size: 16px;
  visibility: visible;
  opacity: 0.9;
  font-size: 24px;
  transition: all 0.45s ease-in-out;
  padding-left: 10px;
  padding: auto;
  border-bottom: 1px solid silver;
}

.Bar {
  position: relative;
  width: 32px;
  height: 2px;
  background: var(--text-secondary);
}

.Bar::before,
.Bar::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 2px;
  right: 0;
  background: var(--text-secondary);
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
}

.Bar::before {
  transform: translateY(-8px);
}

.Bar::after {
  transform: translateY(8px);
}

.Close.open > .Bar {
  transform: translateX(-40px);
  background: transparent;
}

.Close.open > .Bar::before {
  width: 32px;
  transform: rotate(45deg) translate(26px, -26px);
}

.Close.open > .Bar::after {
  transform: rotate(-45deg) translate(26px, 26px);
}

#active {
  color: var(--main-text);
}

.Footer {
  max-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid silver;
}

.Subtotal {
  display: flex;
  justify-content: end;
  align-items: end;
  align-self: end;
  margin-top: 10px;
  margin-right: 22px;
}

.Buttons_Container {
  max-height: 100px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.Checkout .View {
  display: none;
  visibility: hidden;
}

.Checkout.open,
.View.open {
  height: fit-content;
  color: var(--button-text);
  background-color: var(--buttons);
  margin: 0px 20px 0px 20px;
  display: flex;
  width: 360px;
  padding: 10px;
  border: solid var(--button-border) 1px;
  border-radius: 12px;
  justify-content: center;
  visibility: visible;
}

.Checkout.open:active .View.open:active {
  color: var(--button-active);
  transition: 200ms;
}
