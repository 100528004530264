[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Wrapper {
  background-color: var(--background);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
}

.Container {
  color: var(--main-text);
  font-size: 24px;
  margin: auto;
  display: flex;
  max-width: 955px;
  flex-flow: row wrap;
  gap: 15px;
  padding-bottom: max(200px);
}

.Item {
  width: 469px;
  background-color: var(--modal-background);
  text-align: center;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(38, 38, 38);
  border-radius: 5px;
}
