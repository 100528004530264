[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #000;
}

.Container {
  display: flex;
  flex-direction: column;
  height: 95.5vh;
  /* width: 100vw; */
  background-color: var(--background);
  overflow-y: hidden;
}

.Content {
  color: var(--main-text);
}

.Links_Container {
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 945px;
  flex-direction: column;
  margin: auto;
  align-items: center;
}

.Links {
  margin: auto;
  background-color: var(--search);
  min-width: 276px;
  padding-left: 10px;
  color: var(--search);
  margin: 10px 0px;
  text-align: left;
  color: var(--button-text);
  padding: 5px;
  max-width: 500px;
  max-height: 50px;
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 7px;
  cursor: pointer;
}

.Link_Stack {
  display: flex;
  flex-direction: column;
}

.Subtext {
  color: var(--text-secondary);
  font-size: 12px;
}

#Objectionable {
  margin-top: 20px;
}

.Arrow {
  height: 40px;
  width: 40px;
  display: flex;
  margin-left: auto;
  color: var(--button-text);
  background-image: url('/public/Rekishi_Icons/arrow.up@2x.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: center;
  transform: rotate(90deg);
  color: var(--main);
}

.Cache {
  height: 50px;
  width: 80px;
  display: flex;
  margin-left: auto;
  color: var(--main);
  align-items: center;
}

#cache {
  align-items: center;
}
