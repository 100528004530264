[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Navbar {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background);
  border-bottom: solid var(--post-page-border-color) var(--post-page-border-px);
  transition: background-color 0.3s ease;
}

.scrolled {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--background);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.Search__Bar {
  background-color: var(--search);
  color: var(--search-text);
  width: calc(100vw - 600px);
  height: 30px;
  border-radius: 7px;
  display: flex;
  outline: none;
  padding-left: 40px;
  background-image: url('/public/Rekishi_Icons/magnifyingglass@2x.png');
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 10px;
  border: solid var(--post-page-border-color) var(--post-page-border-px);
}

.Search__Bar::placeholder {
  color: var(--secondary-text);
}

#search:target {
  display: flex;
}

.Search__Bar:focus {
  border: 1px solid var(--search);
  border: solid var(--post-page-border-color) var(--post-page-border-px);
  box-shadow: 0px 0px 1px 1px var(--current-border);
}

.Nav__Logo {
  color: white;
}

.Nav__Items {
  margin-right: 60px;
  margin-bottom: 0px;
}

.Nav__Items > ul {
  color: var(--text-secondary);
  font-size: 16px;
  text-decoration: none;
  position: relative;
  opacity: 0.9;
}

.Nav__Items > a {
  color: var(--text-secondary);
  font-size: 16px;
  text-decoration: none;
  margin: 30px;
  position: relative;
  opacity: 0.9;
}

.Nav__Items > a:hover {
  opacity: 1;
}

.Nav__Items > a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: var(--background);
  transition: all 0.45s;
}

.Nav__Items > a:hover::before {
  width: 100%;
}

.Nav__Toggle {
  display: none;
}

@media (max-width: 500px) {
  .Nav__Items {
    position: absolute;
    top: 45px;
    display: flex;
    flex-direction: column;
    background: var(--background);
    left: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: all 0.45s;
    z-index: 100;
  }

  .Nav__Items > a::before {
    background: transparent;
  }

  .Nav__Items.open {
    transform: translateX(0);
  }

  .Nav__Toggle {
    display: flex;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .Bar {
    position: relative;
    width: 32px;
    height: 2px;
    background: var(--text-secondary);
  }

  .Bar::before,
  .Bar::after {
    content: '';
    position: absolute;
    height: 2px;
    background: var(--text-secondary);
    border-radius: 2px;
    transition: all 0.45s ease-in-out;
  }

  .Bar::before {
    width: 32px;
    transform: translateY(-8px);
    right: 0;
  }

  .Bar::after {
    width: 32px;
    transform: translateY(8px);
    right: 0;
  }

  .Nav__Toggle.open > .Bar {
    transform: translateX(-40px);
    background: transparent;
  }

  .Nav__Toggle.open > .Bar::before {
    width: 32px;
    transform: rotate(45deg) translate(26px, -26px);
  }

  .Nav__Toggle.open > .Bar::after {
    transform: rotate(-45deg) translate(26px, 26px);
  }
}

.Nav__Items > a > div {
  background-size: 100%;
  z-index: 100;
  height: 30px;
  width: 30px;
  display: inline-flex;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
}

.Feed {
  background-image: url('/public/Rekishi_Icons/house.fill@2x.png');
}

[data-theme='light'] #active > .Feed {
  background-image: url('/public/Rekishi_Icons/house.fill@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Feed {
  background-image: url('/public/Rekishi_Icons/house.fill@2x\ 2.png');
  filter: grayscale(0);
}

.Media {
  background-image: url('/public/Rekishi_Icons/book@2x\ 2.png');
}

[data-theme='light'] #active > .Media {
  background-image: url('/public/Rekishi_Icons/book@2x\ 2.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Media {
  background-image: url('/public/Rekishi_Icons/book@2x.png');
  filter: grayscale(0);
}

.Search {
  background-image: url('/public/Rekishi_Icons/magnifyingglass@2x\ 2.png');
}

[data-theme='light'] #active > .Search {
  background-image: url('/public/Rekishi_Icons/magnifyingglass@2x\ 2.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Search {
  background-image: url('/public/Rekishi_Icons/magnifyingglass@2x.png');
  filter: grayscale(0);
}

.Post {
  background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
}

[data-theme='light'] #active > .Post {
  background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Post {
  background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x\ 2.png');
  filter: grayscale(0);
}

.Shop {
  background-image: url('/public/Rekishi_Icons/cart.fill@2x.png');
}

[data-theme='light'] #active > .Shop {
  background-image: url('/public/Rekishi_Icons/cart.fill@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Shop {
  background-image: url('/public/Rekishi_Icons/cart.fill@2x\ 2.png');
  filter: grayscale(0);
}

.Profile {
  background-image: url('/public/Rekishi_Icons/person.fill@2x.png');
}

[data-theme='light'] #active > .Profile {
  background-image: url('/public/Rekishi_Icons/person.fill@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Profile {
  background-image: url('/public/Rekishi_Icons/person.fill@2x\ 2.png');
  filter: grayscale(0);
}
