[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Wrapper {
  display: flex;
  align-items: center;
  height: 340px;
  width: 820px;
  background-color: var(--background);
  border-top: var(--data) 1px solid;
  border-bottom: var(--data) 0.5px solid;
}

.Image {
  height: 260px;
  width: 260px;
  margin-right: 60px;
}

.Details {
  display: flex;
  flex-direction: column;
  height: 260px;
  width: 320px;
  background-color: var(--background);
  color: var(--main-text);
  margin-right: 20px;
}

.Name {
  display: flex;
  text-align: left;
  font-size: 20px;
  height: fit-content;
  margin-bottom: 20px;
}

span {
  color: grey;
}

.About {
  display: inline-block;
  width: 100%;
  height: fit-content;
  text-align: left;
}

.Seller {
  display: flex;
  align-content: flex-end;
  margin-top: auto;
}

.Modify {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 260px;
  width: 240px;
  background-color: var(--background);
  color: var(--main-text);
}

.Price {
  display: flex;
  font-size: 24px;
  margin-bottom: 20px;
}

.Amount_Container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 120px;
  width: 100%;
}

.Amount_Buttons {
  display: flex;
  width: 40px;
  height: 34px;
  align-items: center;
  justify-content: center;
  border: solid 1px var(--button-border);
}

.Quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 34px;
  margin: 0px 5px;
  border: solid 1px var(--button-border);
}

.Remove {
  display: flex;
  max-width: 120px;
  width: 100%;
  height: 36px;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  margin-top: auto;
  background-color: var(--buttons);
  color: #fff;
  border-radius: 7px;
}
