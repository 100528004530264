.Container {
  display: flex;
  flex-direction: column;
}

.Image {
  margin: auto;
  height: 468px;
  width: 468px;
  border-radius: 5px;
  cursor: pointer;
}

.Footer {
  display: flex;
  max-width: 500px;
  width: 100%;
  flex-wrap: wrap;
  text-align: left;
  justify-content: right;
}

.Name {
  width: 100%;
}

.Footer_Bottom {
  display: flex;
  width: 100%;
}

.Price {
  width: 100%;
}

.Likes_Container {
  display: flex;
  width: fit-content;
}
