.Overlay:hover {
  height: 225px;
  width: 225px;
}

.Post {
  width: 225px;
  height: 225px;
  border-radius: 5px;
  text-align: center;
}

.Image {
  height: 225px;
  width: 225px;
  border-radius: 5px;
  object-fit: cover;
}

.Text {
  height: 225px;
  width: 225px;
  margin: auto;
  border-radius: 5px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Post:hover {
  cursor: pointer;
}

.Post:hover > .Likes__Comments {
  display: flex;
}

.Likes__Comments {
  display: flex;
  color: var(--button-text);
  position: relative;
  margin: auto;
  height: 225px;
  display: none;
  bottom: 225px;
  font-size: 28px;
  justify-content: center;
  align-items: center;
  background-color: var(--post-overlay);
  opacity: 0.5;
}

.Icons {
  display: inline;
  height: 20px;
  padding-right: 5px;
}

#MessageIcon {
  margin-left: 30px;
}
