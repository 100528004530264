[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #000;
}

.Container {
  height: 95.5vh;
  /* width: 100vw; */
  background-color: var(--background);
  overflow-y: hidden;
}

.Title {
  color: var(--main-text);
  padding-bottom: 25px;
}

.Options_Container {
  color: white;
  width: 400px;
  margin: auto;
  margin-bottom: 300px;
}

.Info {
  background-color: var(--search);
  padding: 10px 5px;
  border-radius: 7px;
}

#premium {
  background-color: var(--search);
  border-radius: 7px;
  padding: 5px 0px;
  margin-top: 10px;
  color: red;
}

#subscribe {
  background-color: var(--search);
  border-radius: 7px;
  padding: 5px 0px;
  margin-top: 10px;
  color: #00f900;
}

.External__Links {
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
  padding: 20px;
  background-color: var(--background); /* Optional background color for the links container */
}

.Policy,
.Terms {
  margin-top: 10px; /* Adjust as needed */
  display: inline-flex;
}
