[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
  --trash-icon: url('/public/Rekishi_Icons/trash.circle@2x.png');
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
  --trash-icon: url('/public/Rekishi_Icons/trash.circle@2x.png');
}

.Post__Wrapper {
  margin-top: -458px;
  height: 130vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
}

.Post__Container {
  background-color: var(--background);
  height: 635px;
  width: 900px;
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  border-radius: 7px;
}

.Type__Nav {
  background-color: var(--secondary-background);
  height: 30px;
  width: stretch;
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

#TypeNav {
  color: var(--main-text);
  background-color: var(--secondary-background);
  border-radius: var(--nav-border-radius);
}

#Current {
  background-color: var(--current);
  color: white;
  border: solid 1px var(--current-border);
  box-shadow: 0px 0px 1px 1px var(--current-border);
  border-radius: 5px;
}

.Nav__Button {
  height: 30px;
  width: 300px;
}

.Nav__Button:hover {
  border: solid 1px #929299;
  box-shadow: 0px 0px 1px 1px #929299;
  border-radius: 5px;
}

.Nav__Button:active {
  color: #929299;
}

.Variable__Post {
  color: var(--main-text);
  height: 600px;
  width: stretch;
  display: flex;
  flex-direction: column;
}

.Variable__Container {
  color: var(--main-text);
  height: 580px;
  width: stretch;
  display: flex;
  flex-direction: column;
}

.Title {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 35px;
  width: stretch;
  display: flex;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Text {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 340px;
  width: stretch;
  padding-bottom: 310px;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Community {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 35px;
  width: stretch;
  display: flex;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Spoiler {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 35px;
  width: stretch;
  display: flex;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.NSFW {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 35px;
  width: 900px;
  display: flex;
  padding-left: 10px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

/* Media tab styling */

.Media__Container {
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Preview {
  background-color: var(--secondary-background);
  display: flex;
  height: 395px;
  width: 320px;
  margin-right: 20px;
  background-image: url('/public/Defaults/Transparent.png');
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: 320px 395px;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Video__Display {
  background-color: #1c1c1e;
  height: 393px;
  width: 320px;
}

.Image__Display {
  height: 393px;
  width: 320px;
}

.Close {
  position: relative;
  top: 0px;
  left: 320px;
  cursor: pointer;
  border: solid 1px green;
}

.Trash {
  height: 40px;
  width: 40px;
  position: absolute;
  width: 32px;
  right: 5px;
  background-image: var(--trash-icon);
  background-position: center;
  background-size: 30px;
  background-repeat: no-repeat;
  z-index: 1;
}

.Nav {
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.Forward {
  height: 25px;
  width: 25px;
  left: 290px;
  bottom: 0px;
  position: absolute;
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  background-repeat: no-repeat;
  background-color: white;
  background-position: 40% 50%;
  background-size: 40%;
  border-radius: 60%;
  opacity: 0.75;
  transform: rotate(180deg);
}

.Back {
  height: 25px;
  width: 25px;
  bottom: 0px;
  position: absolute;
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  background-repeat: no-repeat;
  background-color: white;
  background-position: 40% 50%;
  background-size: 40%;
  border-radius: 60%;
  opacity: 0.75;
  border-radius: 60%;
}

.Media__Title {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 395px;
  width: 555px;
  padding-bottom: 367px;
  padding-left: 10px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

/* Url tab styling */

.Url__Container {
  background-color: var(--background);
  display: flex;
  justify-content: space-between;
}

.Url {
  background-color: var(--secondary-background);
  height: 395px;
  width: 320px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.SubUrl__Container {
  height: 415px;
  display: flex;
  flex-direction: column;
}

.Url__Title {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 190px;
  width: 555px;
  padding-bottom: 160px;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Url__Link {
  color: var(--main-text);
  background-color: var(--secondary-background);
  height: 190px;
  width: 555px;
  padding-bottom: 160px;
  padding-left: 10px;
  margin-bottom: 20px;
  outline: none;
  border: solid var(--post-page-border-px) var(--post-page-border-color);
  border-radius: 5px;
}

.Post__Button__Container {
  background-color: var(--background);
  padding-bottom: 40px;
}

.Post {
  color: var(--button-text);
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
  padding: 10px;
  width: 360px;
  display: flex;
  justify-content: center;
  background-color: var(--disabled-buttons);
  border-radius: 5px;
  border: solid silver 1px;
}

.Post:enabled {
  color: var(--button-text);
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 10px;
  width: 360px;
  display: flex;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
}

.Post:enabled:active {
  color: #7764ea;
  transition: 200ms;
}
