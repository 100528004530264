[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
}

html {
  background-color: #000;
}

.App {
  text-align: center;
  /* background-color: #000; */
}

/* For Webkit based browsers like Chrome */
/* ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f2f2f2;
} */
