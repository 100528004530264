[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--post-overlay);
  opacity: 0.7;
  z-index: 0;
}

.Modal {
  position: fixed;
  height: 590px;
  width: 590px;
  background-color: var(--modal-background);
  display: flex;
  flex-flow: column;
  margin: auto;
  border-radius: 7px;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 60px;
  z-index: 10;
}

.Header {
  background-color: var(--secondary-background);
  height: 60px;
  width: 598px;
  font-size: 20px;
  color: var(--main-text);
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 10;
  border: solid 1px var(--modal-borders);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  text-align: center;
  margin: auto;
  padding: 10px;
}

.Body {
  background-color: var(--modal-background);
  opacity: 1;
  z-index: 10;
  height: 538px;
  width: 598px;
  color: var(--main-text);
  border-top: none;
  border: solid 1px var(--modal-borders);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding-top: 45px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  flex-direction: column;
}

.Text {
  text-align: left;
}

.Resend {
  display: inline-block;
  padding-top: 45px;
  padding-bottom: 45px;
  color: var(--main-text);
  text-align: left;
}

.Body > button {
  color: var(--button-text);
  margin: auto;
  padding: 10px;
  width: 360px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
  margin-top: 100px;
}

.Body > button:active {
  background-color: var(--button-active);
}
