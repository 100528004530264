[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Feed__Container {
  background-color: var(--background);
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  /* width: 100vw; */
}

.Loading {
  background-color: var(--background);
  height: 100vh;
  width: 100vw;
}

.Search__Item {
  width: 100%;
  background-color: var(--background);
  display: flex;
  flex-direction: column;
}

.Alphabetical {
  color: var(--main-text);
  font-size: 18px;
  background-color: var(--modal-background);
  width: 100%;
  margin: auto;
  text-align: left;
  padding-left: 80px;
  border: solid 0.5px var(--modal-borders);
  border-left: none;
  border-right: none;
  border-top: none;
}

.Community__Container {
  padding: 0px;
  padding-right: 10px;
  padding-left: 30px;
  width: 99%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  border: solid 1px var(--background);
  box-shadow: 0px 0px 5px 5px var(--background);
}

.Community__Container:hover {
  border: solid 1px #929299;
  margin-bottom: 5px;
  box-shadow: 0px 0px 5px 5px #929299;
}

.Community__Container:active {
  box-shadow: none;
}

.Community__Container:hover > .About {
  color: var(--main-text);
  background-color: var(--background);
  padding: 10px;
  text-align: left;
  margin: auto;
  max-width: 700px;
  border-radius: 7px;
}

.About {
  margin: auto;
  color: transparent;
}

.Image {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  min-width: 120px;
  display: flex;
  float: left;
}

.Community {
  color: var(--main-text);
  margin-left: 20px;
  font-size: 18px;
  white-space: nowrap;
  padding: 10px;
}

.Followers {
  color: #686868;
  font-size: 18px;
  height: 122px;
  white-space: nowrap;
  padding-right: 50px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
