[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.ForgotPassword__Wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--background);
  color: var(--data);
}

.Logo {
  margin: auto auto 30px;
}

.ForgotPassword__Form__Group {
  margin-bottom: 30px;
}

.ForgotPassword__Form__Inputs {
  background-color: var(--input-fields);
  border-radius: 5px;
  max-height: 7vh;
  width: 400px;
  height: 50px;
  min-width: 276px;
  padding-left: 10px;
  color: var(--input-text);
}

.ForgotPassword__Form__Inputs:-webkit-autofill,
.ForgotPassword__Form__Inputs:-webkit-autofill:hover,
.ForgotPassword__Form__Inputs:-webkit-autofill:focus,
.ForgotPassword__Form__Inputs:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-fields) inset;
  padding-left: 10px;
}

.ForgotPassword__Form__Inputs:active,
.ForgotPassword__Form__Inputs:focus {
  outline: solid 3px var(--buttons);
  transition: 200ms;
}

.ForgotPassword__Form__Inputs::placeholder {
  color: var(--data);
}

.ForgotPassword__Form__Inputs:focus::placeholder {
  color: transparent;
}

#ForgotPassword__Submit {
  padding-left: 0px;
}

#ForgotPassword__Submit:hover {
  cursor: pointer;
}

#ForgotPassword__Submit:enabled {
  background-color: var(--buttons);
}

#ForgotPassword__Submit:active {
  color: var(--button-active);
  transition: 200ms;
}

#Link {
  display: inline-block;
  margin-top: 10px;
}
