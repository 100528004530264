[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
}

/* .Required__Screen {
  background-color: var(--screen);
  height: 100vh;
  width: 100vw;
  color: var(--main);
  opacity: 0.7;
  z-index: 1;
} */

.Account__Wrapper {
  background-color: var(--background);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
  /* scrollbar-color: pink; */
}

.Profile__Wrapper.screened {
  background-color: var(--screen);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
  opacity: 0.7;
  z-index: 1;
}

.User__Name {
  display: block;
  color: var(--main-text);
  padding-bottom: 5px;
  text-align: center;
}

.Profile__Banner {
  height: 240px;
  margin-bottom: 160px;
  border-radius: 20px;
}

.Banner {
  color: var(--background);
  height: 240px;
  width: 945px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
}

.Circle__Profile {
  height: 275px;
  width: 275px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 45px;
  position: relative;
  top: -180px;
  z-index: 0;
  object-fit: cover;
  cursor: pointer;
}

.User__Bio {
  color: white;
  margin: auto;
  width: 945px;
  text-align: center;
  border: solid 1px red;
}

.Enter__Bio {
  background-color: var(--input-fields);
  outline: none;

  border: solid 1px red;
}

.User__Birthday {
  color: white;
  border: solid red 1px;
}

.Birthday__Stuff {
  color: white;
  border: solid red 1px;
}

.Enter__Birthday {
  background-color: var(--input-fields);
  border: solid red 1px;
}

.Confirm {
  border: solid red 1px;
}

@media (max-width: 980px) {
  .Posts__Container {
    max-width: 705px;
    /* justify-content: center; */
  }
}

@media (max-width: 945px) {
  .Open__Banner__Modal {
    color: var(--modal-background);
    height: 50px;
    width: 50px;
    align-self: center;
    justify-self: center;
    text-align: center;
    position: relative;
    top: -22px;
    left: calc(48%);
    background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
    background-repeat: no-repeat;
  }

  .User__Bio {
    width: auto;
  }

  .Stats > span {
    min-width: 160px;
  }
}

@media (max-width: 705px) {
  .Posts__Container {
    width: auto;
    justify-content: center;
  }
}
