[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
}

.Info__Container {
  background-color: var(--background);
}

.Profile__Wrapper {
  background-color: var(--background);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
  /* scrollbar-color: pink; */
}

.Profile__Wrapper.screened {
  background-color: var(--screen);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
  opacity: 0.7;
  z-index: 1;
}

.User__Name {
  display: block;
  color: var(--main-text);
  padding-bottom: 5px;
  text-align: center;
}

.Profile__Banner {
  height: 240px;
  margin-bottom: 160px;
  border-radius: 20px;
}

.Banner {
  color: var(--background);
  height: 240px;
  width: 945px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 15px;
  object-fit: cover;
  cursor: pointer;
}

.Circle__Profile {
  height: 275px;
  width: 275px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 45px;
  position: relative;
  top: -180px;
  z-index: 0;
  object-fit: cover;
  cursor: pointer;
}

.User__Bio {
  color: var(--text-secondary);
  margin: auto;
  width: 945px;
  text-align: center;
}

.Stats {
  display: flex;
  max-width: 1440px;
  justify-content: space-evenly;
  font-size: 20px;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  color: var(--main-text);
}

.Posts__Stat {
  max-width: 515px;
  display: flex;
  flex-direction: column;
}

.Fols__Stat {
  max-width: 515px;
  display: flex;
  flex-direction: column;
}

#nums {
  color: var(--buttons);
  text-align: center;
}

.Posts__Container {
  background-color: var(--background);
  color: var(--main-text);
  font-size: 24px;
  margin: auto;
  display: flex;
  justify-content: left;
  width: 945px;
  flex-flow: wrap;
  gap: 15px;
  padding-bottom: max(200px);
}

.Flex__Item__Post {
  height: 225px;
  width: 225px;
  text-align: center;
}

.Post__Image {
  height: 225px;
  width: 225px;
  border-radius: 5px;
}

.Post__Text {
  height: 225px;
  width: 225px;
  border-radius: 5px;
  align-items: center;
  display: flex;
}

.Flex__Item__Post:hover {
  cursor: pointer;
}

.Flex__Item__Post:hover > .Likes__Comments {
  display: flex;
}

.Likes__Comments {
  color: var(--button-text);
  position: relative;
  height: 225px;
  display: none;
  bottom: 225px;
  justify-content: center;
  align-items: center;
  background-color: var(--post-overlay);
  opacity: 0.5;
}

.Icons {
  display: inline;
  height: 20px;
  padding-right: 5px;
}

#MessageIcon {
  margin-left: 30px;
}

.Post__Overlay:hover {
  height: 225px;
  width: 225px;
}

@media (max-width: 980px) {
  .Posts__Container {
    max-width: 705px;
    /* justify-content: center; */
  }
}

@media (max-width: 945px) {
  .Open__Banner__Modal {
    color: var(--modal-background);
    height: 50px;
    width: 50px;
    align-self: center;
    justify-self: center;
    text-align: center;
    position: relative;
    top: -22px;
    left: calc(48%);
    background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
    background-repeat: no-repeat;
  }

  .User__Bio {
    width: auto;
  }

  .Stats > span {
    min-width: 160px;
  }
}

@media (max-width: 705px) {
  .Posts__Container {
    width: auto;
    justify-content: center;
  }
}

/* New Code */

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 100vw; */
  /* color: white; */
  background-color: var(--background);
  color: var(--main-text);
}

.Banner__Container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 240px;
}

.BannerIMG {
  width: stretch;
  border-radius: 12px;
}

.ProfileIMG {
  max-width: 137.5px;
  min-height: 137.5px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
  left: 25px;
  bottom: 110px;
}

.Info__Container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 160px;
}

.Left__Info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.Name__Bio {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-height: 103px;
  position: relative;
  top: -80px;
}

.Username {
  color: var(--data);
  margin: 5px 0px 10px;
}

.Right__Info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 545px;
}

.Stats__Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-height: 75px;
}

.Stats__Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.Stats__Item > div {
  display: flex;
}

.Stat {
  color: var(--data);
}

.FollowBTN {
  background-color: var(--buttons);
  align-self: end;
  margin: auto;
  margin-bottom: 25px;
  width: 100%;
  max-width: 240px;
  height: 50px;
  margin-right: 5px;
  border-radius: 20px;
  border: 1px solid var(--button-border);
}

.FollowBTN:active {
  color: var(--button-active);
}

.Posts__Container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 945px;
  margin: 0 auto;
}

.Example {
  width: 225px;
  height: 225px;
  border: solid 1px blue;
}

.null {
  display: none;
}
