[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--screen);
  opacity: 0.7;
  z-index: 0;
}

.Modal {
  height: 600px;
  width: 600px;
  margin: auto;
  display: flex;
  flex-flow: column wrap;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 60px;
  z-index: 10;
}

.Header {
  height: 60px;
  width: 598px;
  background-color: var(--modal-background);
  color: var(--main-text);
  font-size: 20px;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 10;
  display: inline-flex;
  border: solid 1px var(--modal-borders);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.Close {
  position: absolute;
  right: 0px;
  padding: 22px;
  z-index: 10;
  cursor: pointer;
}

.Bar {
  position: absolute;
  width: 32px;
  height: 2px;
  top: 25px;
  right: 5px;
  z-index: 10;
}

.Bar::before {
  content: '';
  position: absolute;
  transform: rotate(45deg) translate(0px, -0px);
  width: 32px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: #3c00dc;
  cursor: pointer;
}

.Bar::after {
  content: '';
  position: absolute;
  transform: rotate(-45deg) translate(0px, 0px);
  width: 32px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: #3c00dc;
  cursor: pointer;
}

.Menu {
  height: 538px;
  width: 598px;
  margin: auto;
  background-color: var(--modal-background);
  opacity: 1;
  z-index: 10;
  display: flex;
  flex-flow: column wrap;
  top: 60px;
  position: absolute;
  border-top: none;
  border: solid 1px #7f7f7f;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.Circle__Profile {
  height: 275px;
  width: 275px;
  background-size: cover;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 40px;
  top: 0px;
  display: block;
  object-fit: cover;
}

.Choose__Button {
  color: var(--button-text);
  margin: auto;
  padding: 5px;
  width: 240px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
}

.Choose__Button:active {
  color: var(--button-active);
  transition: 200ms;
}

.Choose__File {
  color: var(--button-text);
  width: fit-content;
  display: inline;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding-left: 105px;
}

.Upload {
  color: var(--button-text);
  margin: auto;
  padding: 10px;
  width: 360px;
  display: inline-block;
  background-color: var(--disabled-buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
}

.Upload:enabled {
  color: var(--button-text);
  margin: auto;
  padding: 10px;
  width: 360px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
}

.Upload:enabled:active {
  color: #7764ea;
  transition: 200ms;
}
