[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--post-overlay);
  opacity: 0.97;
}

.Modal {
  height: 90vh;
  width: 40vw;
  background-color: transparent;
  display: flex;
  position: fixed;
  top: 5vh;
  z-index: 10;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.SequenceCanvas {
  height: inherit;
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.Sequences {
  display: flex;
}

.Nav {
  position: absolute;
  background-color: white;
  border-radius: 60%;
  background-repeat: no-repeat;
  background-position: 40% 50%;
  background-size: 40%;
  opacity: 0.75;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 10;
}

#Back {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  left: -45px;
}

#Forward {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  transform: rotate(180deg);
  right: -45px;
}

@media (max-width: 850px) {
  .Modal {
    height: 55vh;
    width: 75vh;
  }
}
