[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  color: white;
}

.Header {
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: space-between;
}

.Item {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
}

.Cart {
  display: flex;
  height: 100%;
  max-width: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-image: url('/public/Rekishi_Icons/cart.fill@2x.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 36px;
  margin: 0px 20px;
}

.Main {
  display: flex;
}

.Images_Container {
  max-height: 800px;
  height: 100%;
  max-width: 800px;
  width: 100%;
  margin: 00px 20px 0px;
}

.Image {
  height: 100%;
  width: 100%;
}

.Info_Container {
  max-width: 580px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  margin: 60px 20px 0px 0px;
  font-size: 24px;
}

span {
  color: grey;
}

.Seller_Container {
  display: flex;
  height: 40px;
}

.Seller {
  display: flex;
  width: stretch;
  height: 100%;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 5px;
}

.Like_Container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.About {
  display: inline-block;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: left;
  text-align: left;
  margin: 0px 0px 5px;
}

.Condition {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 5px;
}

.Quantity {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 5px;
}

.Price {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 5px;
}

.Shipping {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: left;
  margin: 0px 0px 5px;
}

.Buttons {
  margin: 60px 0px;
}

.Button {
  color: var(--button-text);
  background-color: var(--buttons);
  margin: 10px 20px 10px 20px;
  display: inline-block;
  width: 360px;
  padding: 10px;
  box-sizing: content-box;
  border: solid var(--button-border) 1px;
  border-radius: 50px;
  text-align: center;
}

.Button:active {
  color: var(--button-active);
  transition: 200ms;
}

@media (max-width: 950px) {
  .Info_Container {
    display: none;
  }
}
