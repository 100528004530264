.Text {
  padding: 10px 300px 20px;
  text-align: left;
  color: #ffffff;
  background-color: black;
}

h1 {
  font-size: 32px;
  padding-top: 20px;
}

h2 {
  font-size: 20px;
  padding-top: 10px;
  font-weight: 600;
}
