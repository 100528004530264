[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Alphabetical {
  color: var(--main-text);
  font-size: 18px;
  background-color: var(--modal-background);
  width: 100%;
  margin: auto;
  text-align: left;
  padding-left: 37px;
  border: solid 0.5px var(--modal-borders);
  border-left: none;
  border-right: none;
  border-top: none;
}

.Media__Item {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  border: solid 1px black;
  box-shadow: 0px 0px 5px 5px var(--background);
}

.Media__Item:hover {
  border: solid 1px #929299;
  margin-bottom: 5px;
  box-shadow: 0px 0px 5px 5px #929299;
}

.Media__Container {
  padding-left: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 0px 5px 5px var(--background);
}

.Media__Container:active {
  box-shadow: none;
}

.Media__Container:hover > .About {
  color: var(--main-text);
  background-color: var(--background);
  padding: 10px;
  text-align: left;
  margin: auto;
  max-width: 800px;
  border-radius: 7px;
  z-index: 100;
}

.About {
  margin: auto;
  max-width: 800px;
  color: transparent;
}

.Image {
  height: 200px;
  max-width: 150px;
  min-width: 150px;
  display: flex;
  float: left;
  border-radius: 5px;
}

.Media {
  display: flex;
  flex-direction: column;
  color: var(--main-text);
  margin-left: 20px;
  font-size: 18px;
  white-space: nowrap;
  padding: 10px;
  text-align: left;
}

span {
  color: var(--main-text);
}

#views,
#episodes {
  color: #686868;
}

.Likes {
  color: var(--main-text);
  font-size: 18px;
  padding-right: 50px;
  padding-left: 5px;
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  box-shadow: 0px 0px 5px 5px var(--background);
}

.Likes__Icon {
  height: 25px;
  width: 25px;
  background-image: url('/public/Rekishi_Icons/heart@2x 2.png');
  background-repeat: no-repeat;
  background-size: 25px;
  margin-left: 5px;
  cursor: pointer;
  z-index: 100;
}

.Likes__Icon.likedMedia {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}

.Likes__Icon.light {
  background-image: url('/public/Rekishi_Icons/heart@2x\ 2.png');
}

.Likes__Icon.likedMedia.light {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}
