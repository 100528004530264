[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.Navbar {
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  background-color: var(--background);
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 15px;
  padding-right: 10px;
  transition: background-color 0.3s ease;
}

.scrolled {
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  top: 0px;
  right: 0px;
  padding-top: 15px;
  padding-right: 10px;
  height: 50px;
  width: 50px;
  background-color: var(--background);
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.Navbar.open {
  height: 100%;
  width: 425px;
  display: flex;
  flex-flow: column wrap;
  background-color: var(--background);
  border: solid var(--text-secondary) 2px;
  transform: translateX(0%) translateY(0px) translateZ(0px);
  transition: all 0.45s;
  z-index: 1;
  position: fixed;
}

.Nav__Items > a {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.Nav__Items.open > a {
  color: var(--text-secondary);
  display: inline-flex;
  width: 400px;
  font-size: 16px;
  text-decoration: none;
  margin: 20px 0px 0px 20px;
  visibility: visible;
  opacity: 0.9;
  align-items: center;
  transition: all 0.45s;
}

.Nav__Items.open > a:hover {
  opacity: 1;
}

.Nav__Items.open > a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: var(--background);
}

.Nav__Items.open > a:hover::before {
  width: 100%;
}

.Nav__Items.open {
  position: absolute;
  top: 60px;
  display: flex;
  flex-direction: column;
  background: var(--background);
  left: 0;
  width: 20%;
  height: 50%;
  transform: translateX(-100%);

  z-index: 100;
}

.Nav__Items > a::before {
  background: transparent;
}

.Nav__Items.open {
  transform: translateX(0);
  width: 50px;
  display: flex;
}

.Nav__Toggle {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-top: 10px;
  margin-bottom: 10px;
}

.Nav__Toggle.open {
  display: flex;
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  left: 370px;
  top: 5px;
}

.Them__Container {
  display: none;
  visibility: hidden;
  opacity: 0;
  padding-left: 15px;
}

.Theme__Container.open {
  position: absolute;
  margin: 0px 0px 0px 20px;
  color: var(--text-secondary);
  flex-direction: column;
  font-size: 16px;
  visibility: visible;
  opacity: 0.9;
  transition: all 0.45s;
}

.Theme__Button {
  color: var(--main);
  visibility: hidden;
  display: none;
}

.Theme__Button.open {
  display: flex;
  visibility: visible;
}

.Theme__Toggle__Icon {
  color: var(--main);
  padding-left: 15px;
  display: none;
  visibility: hidden;
}

.Theme__Toggle__Icon.open {
  padding-left: 15px;
  display: flex;
  visibility: visible;
}

.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider__Round {
  display: flex;
  visibility: visible;
}

.Slider__Round.open {
  display: flex;
  cursor: pointer;
  height: 15px;
  width: 27px;
  margin-left: 15px;
  background-color: var(--text-secondary);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider__Round.open:before {
  display: flex;
  content: '';
  height: 15px;
  width: 15px;
  left: 0px;
  bottom: 0px;
  background-color: var(--buttons);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .Slider__Round.open {
  background-color: var(--secondary-text);
}

input:focus + .Slider__Round.open {
  box-shadow: 0 0 1px var(--secondary-text);
}

input:checked + .Slider__Round.open:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.Slider__Round.open {
  border-radius: 34px;
}

.Slider__Round.open:before {
  border-radius: 50%;
}

.Bar {
  position: relative;
  width: 32px;
  height: 2px;
  background: var(--text-secondary);
}

.Bar::before,
.Bar::after {
  content: '';
  position: absolute;
  width: 32px;
  height: 2px;
  right: 0;
  background: var(--text-secondary);
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
}

.Bar::before {
  transform: translateY(-8px);
}

.Bar::after {
  transform: translateY(8px);
}

.Nav__Toggle.open > .Bar {
  transform: translateX(-40px);
  background: transparent;
}

.Nav__Toggle.open > .Bar::before {
  width: 32px;
  transform: rotate(45deg) translate(26px, -26px);
}

.Nav__Toggle.open > .Bar::after {
  transform: rotate(-45deg) translate(26px, 26px);
}

#active {
  color: var(--main-text);
}

.Sidebar__Titles {
  color: var(--tertiary-text);
  align-self: flex-start;
}

.Sidebar__Descriptions {
  display: none;
  visibility: hidden;
}

.Sidebar__Descriptions.open {
  color: var(--tertiary-text);
  display: inline-flex;
  margin-bottom: 20px;
  width: 400px;
  font-size: 12px;
  visibility: visible;
}

.Logout__Button {
  display: none;
  visibility: hidden;
}

.Logout__Button.open {
  color: var(--button-text);
  background-color: var(--buttons);
  margin: 10px 20px 0px 20px;
  display: inline-block;
  width: 360px;
  padding: 10px;
  box-sizing: content-box;
  border: solid var(--button-border) 1px;
  border-radius: 5px;
  text-align: center;
  visibility: visible;
}

.Logout__Button.open:active {
  color: var(--button-active);
  transition: 200ms;
}

.Link__Container {
  display: flex;
  flex-direction: column;
  margin-left: 45px;
}

.YourAccount {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/person@2x.png');
}

[data-theme='light'] #active > .YourAccount {
  background-image: url('/public/Rekishi_Icons/person@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .YourAccount {
  background-image: url('/public/Rekishi_Icons/person@2x\ 2.png');
  filter: grayscale(0);
}

.Content {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/folder@2x.png');
  margin-top: 10px;
}

[data-theme='light'] #active > .Content {
  background-image: url('/public/Rekishi_Icons/folder@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Content {
  background-image: url('/public/Rekishi_Icons/folder@2x\ 2.png');
  filter: grayscale(0);
}

.ADL {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/line.3.horizontal@2x.png');
  margin-top: 20px;
}

[data-theme='light'] #active > .ADL {
  background-image: url('/public/Rekishi_Icons/line.3.horizontal@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .ADL {
  background-image: url('/public/Rekishi_Icons/line.3.horizontal@2x\ 2.png');
  filter: grayscale(0);
}

.RekishiPremium {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/dollarsign.circle@2x.png');
  margin-top: 5px;
}

[data-theme='light'] #active > .RekishiPremium {
  background-image: url('/public/Rekishi_Icons/dollarsign.circle@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .RekishiPremium {
  background-image: url('/public/Rekishi_Icons/dollarsign.circle@2x\ 2.png');
  filter: grayscale(0);
}

.Purchases {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/cart.fill@2x.png');
  margin-top: 5px;
}

[data-theme='light'] #active > .Purchases {
  background-image: url('/public/Rekishi_Icons/cart.fill@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Purchases {
  background-image: url('/public/Rekishi_Icons/cart.fill@2x\ 2.png');
  filter: grayscale(0);
}

.Security {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/lock@2x.png');
  margin-bottom: 15px;
}

[data-theme='light'] #active > .Security {
  background-image: url('/public/Rekishi_Icons/lock@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Security {
  background-image: url('/public/Rekishi_Icons/lock@2x\ 2.png');
  filter: grayscale(0);
}

.Safety__Privacy {
  background-size: 100%;
  height: 42px;
  width: 30px;
  background-repeat: no-repeat;
  position: absolute;
  margin-bottom: 10px;
  background-image: url('/public/Rekishi_Icons/shield@2x.png');
  margin-bottom: 10px;
}

[data-theme='light'] #active > .Safety__Privacy {
  background-image: url('/public/Rekishi_Icons/shield@2x.png');
  filter: grayscale(1);
}

[data-theme='dark'] #active > .Safety__Privacy {
  background-image: url('/public/Rekishi_Icons/shield@2x\ 2.png');
  filter: grayscale(0);
}
