[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Feed__Wrapper {
  background-color: var(--background);
  height: 100%;
  /* width: 100vw; */
  color: var(--main);
}

.Posts__Container {
  color: var(--main-text);
  font-size: 24px;
  margin: auto;
  display: flex;
  justify-content: center;
  max-width: 945px;
  flex-flow: row wrap;
  gap: 15px;
  padding-bottom: max(200px);
}

.Flex__Item__Post {
  width: 469px;
  background-color: var(--modal-background);
  text-align: center;
  margin-bottom: 15px;
  border-bottom: 1px solid rgb(38, 38, 38);
  border-radius: 5px;
}

.Header {
  display: flex;
  width: 470px;
  align-items: center;
}

.Header__Poster {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.Header__Info {
  display: flex;
  font-size: 16px;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.Title {
  font-size: 14px;
}

.Dots__Container {
  height: 26px;
  align-items: center;
  cursor: pointer;
  margin-right: 7px;
}

.Dots {
  display: flex;
  color: var(--data);
  font-size: 30px;
  align-items: center;
  height: 10px;
}

.Post__Image {
  margin: auto;
  height: 468px;
  width: 468px;
  border-radius: 5px;
  cursor: pointer;
}

.Footer {
  display: flex;
  width: 470px;
  justify-content: space-between;
}

.Subs__Poster {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;
  font-size: 14px;
  cursor: pointer;
}

.Sub,
.Poster {
  display: flex;
  padding-left: 2px;
  cursor: pointer;
}

.Sub {
  color: var(--data);
}

.Poster {
  color: grey;
}

.Test {
  display: flex;
  color: white;
  width: 100px;
  height: 40px;
}

.Icons__Time {
  display: flex;
  flex-direction: column;
}

.Footer__Icons {
  display: flex;
  align-items: center;
  font-size: 18px;
  width: fit-content;
}

#LikeIcon {
  cursor: pointer;
}

#MessageIcon {
  cursor: pointer;
}

.Time {
  display: flex;
  font-size: 12px;
  color: gray;
  align-items: center;
  justify-content: center;
}

.Caption {
  display: flex;
  height: 18px;
  width: 470px;
  font-size: 12px;
}

.Comments {
  display: flex;
  height: 18px;
  width: 470px;
  font-size: 12px;
  color: grey;
}

.Add__Comment {
  display: flex;
  height: 18px;
  width: 470px;
  font-size: 12px;
  color: grey;
}

.Post__Text {
  height: 225px;
  width: 225px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  margin: auto;
}

.Icons {
  display: inline;
  height: 35px;
  padding: 8px;
}

.Like__Icon {
  background-image: url('/public/Rekishi_Icons/heart@2x 2.png');
  display: inline-block;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.Like__Icon.largeLiked {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}

.Like__Icon.light {
  background-image: url('/public/Rekishi_Icons/heart@2x\ 2.png');
}

.Like__Icon.largeLiked.light {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}
