[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Back {
  color: var(--button-text);
  padding: 5px;
  max-width: 75px;
  width: 100%;
  display: flex;
  background-color: var(--buttons);
  border: solid 1px var(--buttons);
  border-radius: 5px;
  margin: 0px 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Arrow {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  display: flex;
  color: var(--button-text);
  background-image: url('/public/Rekishi_Icons/arrow.up@2x\ 2.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  transform: rotate(270deg);
}

@media (max-width: 700px) {
  .Back {
    width: 40px;
    font-size: 0px;
    justify-content: center;
    display: hidden;
  }
}
