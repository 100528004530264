[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
}

.Container {
  background-color: var(--modal-background);
  padding: 10px;
  padding-left: 0px;
  display: flex;
}

.Commenter__Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
}

.Profile {
  background-color: var(--modal-background);
  height: 30px;
  width: 30px;
  margin-right: 15px;
  border-radius: 50px;
  display: block;
  margin: auto;
}

.Username {
  background-color: var(--modal-background);
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.Comment__Container {
  background-color: var(--modal-background);
  display: flex;
  justify-content: space-between;
  width: stretch;
}

.Comment {
  background-color: var(--modal-background);
  color: var(--main-text);
  font-size: 14px;
}

.Stack {
  display: flex;
  flex-flow: column;
}

.Likes__Container {
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  text-align: center;
}

.Likes {
  background-color: var(--modal-background);
  font-size: 14px;
  height: 20px;
  width: 20px;
}

.Like {
  background-color: var(--modal-background);
  height: 20px;
  width: 20px;
  background-image: url('/public/Rekishi_Icons/heart@2x.png');
  background-size: 80%;
  align-self: center;
  justify-self: center;
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.Like.liked {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x\ 2.png');
}

.Meta__Container {
  background-color: var(--modal-background);
  display: flex;
  justify-content: end;
}

.Weeks {
  background-color: var(--modal-background);
  height: 20px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  margin-right: 5px;
}

.Reply {
  background-color: var(--modal-background);
  height: 20px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
}

.Options {
  background-color: var(--modal-background);
  height: 20px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  margin-right: 5px;
  cursor: pointer;
}
