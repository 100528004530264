[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --post-exclusive: #aaaaaa;
  --nav-border-radius: 0px;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: #3b00dc;
  --post-page-border-px: 1px;
  --current: #3b00dc;
  --current-border: #aaaaaa;
  --media-preview: url('/public/Defaults/Image-Default-Light.png');
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary-background: #000;
  --post-exclusive: #606064;
  --nav-border-radius: 5px;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --post-page-border-color: transparent;
  --post-page-border-px: 1px;
  --current: #606064;
  --current-border: #929299;
  --media-preview: url('/public/Defaults/Image-Default-Dark.png');
}

.Thumbnail {
  border: 1px solid red;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  opacity: 0.7;
  z-index: 0;
}

.Modal {
  height: 600px;
  width: 600px;
  padding-top: 20px;
  margin: auto;
  background-color: var(--modal-background);
  opacity: 1;
  z-index: 10;
  display: flex;
  flex-flow: column wrap;
  top: 60px;
  left: 0;
  right: 0;
  position: absolute;
  border-top: none;
  border: solid 1px var(--modal-borders);
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.Close {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 22px;
  z-index: 10;
  cursor: pointer;
}

.Bar {
  position: absolute;
  width: 32px;
  right: 5px;
  z-index: 10;
}

.Bar::before {
  content: '';
  position: absolute;
  transform: rotate(45deg) translate(0px, -0px);
  width: 32px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: #3c00dc;
  cursor: pointer;
}

.Bar::after {
  content: '';
  position: absolute;
  transform: rotate(-45deg) translate(0px, 0px);
  width: 32px;
  height: 2px;
  border-radius: 2px;
  transition: all 0.45s ease-in-out;
  background-color: #3c00dc;
  cursor: pointer;
}

.Image__Preview {
  height: 500px;
  width: 400px;
  background-image: var(--media-preview);
  background-repeat: no-repeat;
  background-position: -50px;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  top: 0px;
  display: block;
  object-fit: cover;
}

.Video__Display {
  height: 480px;
  width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.Image__Display {
  height: 480px;
  width: 380px;
  margin-left: auto;
  margin-right: auto;
}

.Nav {
  height: 40px;
  width: 40px;
  display: flex;
  position: absolute;
  background-repeat: no-repeat;
  background-color: var(--background);
  background-position: 40% 50%;
  background-size: 40%;
  border-radius: 60%;
  opacity: 0.75;
  top: 250px;
  cursor: pointer;
}

#Forward {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  right: 34.5px;
  transform: rotate(180deg);
}

#Back {
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  left: 34.5px;
}

.Select__Images {
  color: var(--button-text);
  margin: auto;
  padding: 5px;
  width: 240px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--buttons);
  border-radius: 5px;
}

.Choose__Button:active {
  color: var(--button-active);
  transition: 200ms;
}

.Choose__File {
  color: var(--button-text);
  width: fit-content;
  display: inline;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  padding-left: 105px;
}

@media (max-height: 1440px) {
  .Modal {
    top: calc(100vh / 5);
  }
}

@media (max-width: 700px) {
  .Modal {
    width: 400px;
  }
}
