[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--background);
  height: 100%;
  width: 100%;
}

.Header {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.Title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 24px;
  color: white;
}

.Container {
  display: flex;
  width: 100%;
  background-color: var(--background);
}

.Cart {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  width: 100%;
  height: fit-content;
  margin: 0px 20px 0px 20px;
  background-color: var(--background);
}

.Summary {
  display: flex;
  flex-direction: column;
  min-height: 600px;
  height: 100%;
  max-width: 480px;
  width: 100%;
  margin: 0px auto;
  background-color: var(--background);
  border: solid 1px var(--button-border);
  color: var(--main-text);
}

.Summary > div {
  display: flex;
  height: 60px;
  padding: 0px 40px;
  align-items: center;
  justify-content: space-between;
}

.Summary > div > span {
  color: var(--main-text);
  font-size: 18px;
}

#Order_Summary {
  display: flex;
  height: 80px;
  align-items: center;
  padding-left: 60px;
  font-size: 24px;
}

#Promo {
  margin: 0px 40px 0px;
  padding: 0px;
  border-top: solid 1px var(--button-border);
  border-bottom: solid 1px var(--button-border);
}

.Drop {
  display: flex;
  height: 20px;
  width: 20px;
  background-image: url('/public/Rekishi_Icons/chevron.left@2x.png');
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 0px;
  background-position-x: 5px;
  transform: rotate(270deg);
}

.Dropdown {
  display: none;
}

.Input {
  display: none;
  text-align: center;
  height: 36px;
  border-radius: 7px;
}

.Apply {
  display: none;
  max-width: 120px;
  width: 100%;
  height: 36px;
  justify-content: center;
  align-items: center;
  align-content: flex-end;
  margin-top: auto;
  background-color: var(--buttons);
  border-radius: 7px;
}

#Savings > span {
  color: red;
}
