[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Communities__Wrapper {
  background-color: var(--background);
  color: var(--main-text);
  height: 100%;
  /* width: 100vw; */
}

.Back {
  color: var(--button-text);
  padding: 5px;
  width: 75px;
  display: flex;
  background-color: var(--buttons);
  border: solid 1px var(--buttons);
  border-radius: 5px;
  margin-left: 75px;
  align-items: center;
  cursor: pointer;
}

.Arrow {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  display: flex;
  color: var(--button-text);
  background-image: url('/public/Rekishi_Icons/arrow.up@2x\ 2.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  transform: rotate(270deg);
}

.Banner {
  display: flex;
  flex-direction: column;
  width: 900px;
  height: 290px;
  margin: auto;
}

.Community {
  width: 100vw;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: baseline;
}

.Com__Name {
  margin: auto;
}

.Com__Info {
  display: flex;
}

.Image__Container {
  display: flex;
  width: 40%;
  align-items: center;
}

.Community__Image {
  border: solid 1px var(--image-border);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin: auto;
}

.Meta {
  height: 250px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Stats__Container {
  display: flex;
  justify-content: space-between;
  width: 240px;
  padding: 5px;
}

#posts,
#members {
  color: var(--data);
  display: flex;
  justify-content: center;
}

.Posts {
  color: var(--main-text);
  padding: 5px;
  width: 100px;
  text-align: center;
}

.Members {
  color: var(--main-text);
  padding: 5px;
  width: 100px;
  text-align: center;
}

.Follow {
  color: var(--button-text);
  padding: 5px;
  width: 240px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
  margin: 5px;
}

.Follow:active {
  color: var(--button-active);
  transition: 200ms;
}

.Unfollow {
  color: var(--button-text);
  padding: 5px;
  width: 240px;
  display: inline-block;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
  margin: 5px;
}

.Unfollow:active {
  color: var(--button-active);
  transition: 200ms;
}

.About {
  margin: auto;
  padding: 10px;
  text-align: left;
}

.Com__Posts {
  padding-top: 30px;
  font-size: 24px;
  margin: auto;
  display: flex;
  justify-content: left;
  width: 945px;
  flex-flow: row wrap;
  gap: 15px;
  /* padding-bottom: max(200px); */
}

.Flex__Item__Post {
  height: 225px;
  width: 225px;
}

.Post__Image {
  height: 225px;
  width: 225px;
  border-radius: 5px;
}

.Post__Text {
  height: 225px;
  width: 225px;
  border-radius: 5px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.Flex__Item__Post:hover {
  cursor: pointer;
}

.Flex__Item__Post:hover > .Likes__Comments {
  display: flex;
}

.Likes__Comments {
  color: var(--button-text);
  position: relative;
  height: 225px;
  display: none;
  bottom: 225px;
  justify-content: center;
  align-items: center;
  background-color: var(--post-overlay);
  opacity: 0.5;
}

.Icons {
  display: inline;
  height: 20px;
  padding-right: 5px;
}

#MessageIcon {
  margin-left: 30px;
}

.Post__Overlay:hover {
  height: 225px;
  width: 225px;
}

.null {
  display: none;
}

@media (max-width: 880px) {
  .Meta {
    width: 40vw;
  }

  .About {
    /* Hide the text. */
    font-size: 0px;
    justify-content: center;
    display: hidden;
  }
}

@media (max-width: 700px) {
  .Back {
    width: 40px;
    font-size: 0px;
    justify-content: center;
    display: hidden;
  }
}

@media (max-width: 650px) {
  .Image__Container {
    width: 300px;
  }
  .Stats__Container {
    width: 150px;
  }

  .Unfollow {
    width: 150px;
  }
}
