[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Landing__Wrapper {
  background-color: var(--background);
  color: var(--main-text);
  height: 100%;
  /* width: 100vw; */
}

.Back {
  color: var(--button-text);
  padding: 5px;
  width: 75px;
  display: flex;
  background-color: var(--buttons);
  border: solid 1px var(--buttons);
  border-radius: 5px;
  margin-left: 75px;
  align-items: center;
  cursor: pointer;
}

.Arrow {
  height: 20px;
  width: 20px;
  margin-right: 5px;
  display: flex;
  color: white;
  background-image: url('/public/Rekishi_Icons/arrow.up@2x\ 2.png');
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  transform: rotate(270deg);
}

.Banner {
  display: flex;
  flex-direction: column;
  width: 945px;
  height: 240px;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 50px;
}

.Landing__Image {
  width: 945px;
  height: 240px;
  border-radius: 10px;
}

.Title {
  color: var(--main-text);
  font-size: 18px;
  height: 30px;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: left;
}

.Author {
  color: var(--main-text);
  font-size: 16px;
  height: 40px;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: center;
}

.Summary {
  color: #686868;
  font-size: 16px;
  height: fit-content;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Views {
  color: var(--main-text);
  font-size: 16px;
  height: 40px;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Episodes__Sequences__Container {
  height: fit-content;
  width: 945px;
  margin: auto;
}

.Episode {
  color: var(--main-text);
  font-size: 16px;
  height: 50px;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  border-top: solid 2px #3c00dc;
  /* border-bottom: solid 2px #3c00dc; */
  cursor: pointer;
}

.Sequence {
  color: var(--main-text);
  font-size: 16px;
  height: 50px;
  width: 945px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: left;
  border-top: solid 2px #3c00dc;
  /* border-bottom: solid 2px #3c00dc; */
  cursor: pointer;
}

.TextAndCommentContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Style for text (episodes or sequences) */
.Text {
  color: var(--main-text);
  font-size: 16px;
  height: 50px;
  width: 945px; /* Adjust the width as needed */
  display: flex;
  align-items: center;
  justify-content: left;
  /* border-top: solid 2px #3c00dc;
  border-bottom: solid 2px #3c00dc; */
  cursor: pointer;
}

/* Style for the comment button */
.CommentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Add margin or padding to create space between text and button */
.CommentButton img {
  width: 30px;
  height: 30px;
  margin-left: 5px; /* Adjust the margin as needed */
}
