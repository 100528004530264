[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Like_Icon {
  background-image: url('/public/Rekishi_Icons/heart@2x 2.png');
  align-self: center;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.Like_Icon.liked {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}

.Like_Icon.light {
  background-image: url('/public/Rekishi_Icons/heart@2x\ 2.png');
}

.Like_Icon.liked.light {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x.png');
}

#iconColor {
  background-image: url('/public/Rekishi_Icons/heart@2x.png');
  align-self: center;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
}

#iconColor.liked {
  background-image: url('/public/Rekishi_Icons/heart.fill@2x 2.png');
  align-self: center;
  height: 30px;
  width: 30px;
  background-position: 40% 50%;
  background-size: 80%;
  background-repeat: no-repeat;
  cursor: pointer;
}
