[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Account__Wrapper.screened {
  height: 100%;
  /* width: 100vw; */
  background-color: var(--screen);
  position: absolute;
  opacity: 0.7;
  visibility: visible;
  z-index: 0;
}

.Required__Screen {
  background-color: var(--background);
  height: 100vh;
  width: 100%;
  color: var(--main);
}

.User__Name {
  display: block;
  color: var(--main-text);
  padding-bottom: 5px;
}

.Profile__Banner {
  height: 215px;
  margin-bottom: 160px;
  border-radius: 20px;
}

.Banner {
  color: var(--background);
  height: 215px;
  width: 900px;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  border-radius: 15px;
  object-fit: cover;
}

.Circle__Profile {
  height: 275px;
  width: 275px;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 45px;
  position: relative;
  top: -180px;
  z-index: 0;
  object-fit: cover;
}

.Open__Banner__Modal {
  color: var(--background);
  height: 50px;
  width: 50px;
  align-self: center;
  justify-self: center;
  text-align: center;
  position: relative;
  top: -22px;
  left: 453px;
  background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
  background-repeat: no-repeat;
}

.Open__Profile__Modal {
  height: 50px;
  width: 50px;
  align-self: center;
  justify-self: center;
  text-align: center;
  position: relative;
  top: -275px;
  left: 104px;
  background-image: url('/public/Rekishi_Icons/plus.diamond.fill@2x.png');
  background-repeat: no-repeat;
}

.User__Bio {
  color: var(--main-text);
  display: flex;
  margin: auto;
  margin-bottom: 25px;
  width: 900px;
  justify-content: center;
}

.User__Birthday {
  color: var(--main-text);
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 900px;
  justify-content: center;
}

.Enter__Bio {
  border-radius: 7px;
  margin-right: 10px;
  background-color: var(--tertiary--background);
  text-align: center;
}

.Enter__Birthday {
  border-radius: 7px;
  margin-right: 10px;
  background-color: var(--tertiary--background);
  text-align: center;
}

.Confirm {
  color: var(--main-text);
  display: flex;
  padding: 10px;
  height: 25px;
  width: 80px;
  background-color: var(--buttons);
  border: solid 1px var(--button-border);
  border-radius: 5px;
  align-items: center;
}

.Confirm:active {
  color: var(--button-active);
  transition: 200ms;
}

.Birthday__Stuff {
  display: flex;
  margin: auto;
}
