[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* width: 100vw; */
  color: white;
}

.Banner__Container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 240px;
}

.BannerIMG {
  width: stretch;
  border-radius: 12px;
}

.ProfileIMG {
  max-width: 137.5px;
  min-height: 137.5px;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
  left: 25px;
  bottom: 110px;
}

.Info__Container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 945px;
  max-height: 160px;
}

.Left__Info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
}

.Name__Bio {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 20px;
  padding-left: 25px;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-height: 103px;
  position: relative;
  top: -80px;
}

.Username {
  color: var(--data);
  margin: 5px 0px 10px;
}

.Right__Info {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 545px;
}

.Stats__Container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-height: 75px;
}

.Stats__Item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.Stats__Item > div {
  display: flex;
}

.Stat {
  color: var(--data);
}

.FollowBTN {
  background-color: var(--buttons);
  align-self: end;
  margin: auto;
  margin-bottom: 25px;
  width: 100%;
  max-width: 240px;
  height: 50px;
  margin-right: 5px;
  border-radius: 20px;
  border: 1px solid var(--button-border);
}

.FollowBTN:active {
  color: var(--button-active);
}

.Posts__Container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 0;
  width: 100%;
  height: 100%;
  max-width: 945px;
  margin: 0 auto;
}

.Example {
  width: 225px;
  height: 225px;
  border: solid 1px blue;
}

.null {
  display: none;
}
