[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #fff;
  --url: url('/public/Rekishi-Logo-Purple.png');
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
  --background-color: #000;
  --url: url('/public/Rekishi-Logo-White.png');
}

.Container {
  display: flex;
  flex-direction: column;
  height: 95.5vh;
  /* width: 100vw; */
  background-color: var(--background);
  overflow-y: hidden;
  align-items: center;
}

.Logo {
  display: flex;
  max-height: 400px;
  max-width: 400px;
  height: 100%;
  width: 100%;
  background-image:var(--url);
  background-size: 100%;
  background-repeat: no-repeat;
  margin-top: 60px;
}

.Purchases {
  display: flex;
  color: var(--main-text);
}

.Purchases__Message {
  color: var(--search);
  display: flex;
  width: 300px;
  margin-top: 20px;
}
