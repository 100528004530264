[data-theme='light'] {
  --background: #fff;
  --secondary-background: #fff;
  --tertiary-background: #fff;
  --search: #aaaaaa;
  --search-text: #000;
  --main-text: #000;
  --secondary-text: #686868;
  --tertiary-text: #000;
  --modal-background: #ebebeb;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #ebebeb;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

[data-theme='dark'] {
  --background: #000;
  --secondary-background: #1c1c1e;
  --tertiary--background: #606064;
  --search: #1c1c1e;
  --search-text: #929299;
  --main-text: #fff;
  --secondary-text: #686868;
  --tertiary-text: #686868;
  --modal-background: #1c1c1e;
  --nav-background: #fff;
  --buttons: #3b00dc;
  --button-text: #fff;
  --button-border: #3b00dc;
  --image-border: #3b00dc;
  --button-active: #7764ea;
  --disabled-buttons: #aaaaaa;
  --input-fields: #aaaaaa;
  --input-text: #fff;
  --text-placeholder: #7764ea;
  --modal-borders: #7f7f7f;
  --secondary-borders: #929299;
  --border-highlights: #929299;
  --accents: #3b00dc;
  --data: #3b00dc;
  --screen: #2e4052;
  --post-overlay: #000;
}

.Checkout {
  display: flex;
  max-width: 380px;
  width: 100%;
  height: 46px;
  margin: auto;
  justify-content: center;
  align-items: center;
  background-color: var(--buttons);
  color: #fff;
  border-radius: 7px;
}

.Checkout:active {
  color: #7764ea;
  transition: 200ms;
}

.Checkout:disabled {
  background-color: var(--disabled-buttons);
}

.Checkout.open {
  height: fit-content;
  color: var(--button-text);
  background-color: var(--buttons);
  margin: 0px 20px 0px 20px;
  display: flex;
  width: 360px;
  padding: 10px;
  border: solid var(--button-border) 1px;
  border-radius: 12px;
  justify-content: center;
  visibility: visible;
}

.Checkout.open:active .View.open:active {
  color: var(--button-active);
  transition: 200ms;
}
